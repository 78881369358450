import React from 'react';
import styles from './Spinner.module.scss';

export interface SpinnerProps {
  loaderRef?: React.Ref<any>;
}

export const Spinner: React.FC<SpinnerProps> = ({ loaderRef }) => {
  return (
    <div ref={loaderRef} className={styles.Spinner}></div>
  );
};
