import React from 'react';
import cn from 'classnames';
import styles from './Header.module.scss';
export interface HeaderProps {}

export const Header: React.FC<HeaderProps> = () => {
  const [isShortDisplay, setIsShortDisplay] = React.useState(false);

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  React.useEffect(() => {
    window.addEventListener('scroll', () => {
      setIsShortDisplay(window.scrollY >= 91);
    });
  }, []);

  return (
    <div className={cn(styles.Header, isShortDisplay ? styles.Short : '')} onClick={scrollToTop}>
      {isShortDisplay && <img src={require('../../assets/images/logo-short.svg').default} />}
      {!isShortDisplay && <img src={require('../../assets/images/logo.svg').default} />}
    </div>
  );
};
