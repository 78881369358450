import React from 'react';
import styles from './MainLayout.module.scss';
import { Header } from '../../components/Header/Header';
import { Footer } from '../../components/Footer/Footer';

export interface MainLayoutProps {}

export const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  return (
    <div className={styles.MainLayout}>
      <Header>MainPage</Header>
      {children}
      <Footer>2023</Footer>
    </div>
  );
};
