import React from 'react';
import styles from './Footer.module.scss';

export interface FooterProps {}

export const Footer: React.FC<FooterProps> = () => {
  return (
    <div className={styles.Footer}>
      <img className={styles.FooterLogo} src={require('../../assets/images/logo-short.svg').default} />
      <div className={styles.FooterYear}>{new Date().getFullYear()}</div>
    </div>
  );
};
