import React from 'react';
import Modal from 'react-modal';
import { MasonryInfiniteGrid } from '@egjs/react-infinitegrid';
import styles from './Grid.module.scss';
import { Tile } from '../Tile/Tile';
import { useLoadImages } from '../../hooks/useLoadImages';
import { Spinner } from '../Spinner/Spinner';

Modal.setAppElement('#root');

export interface GridProps {
  className?: string;
}

export const Grid: React.FC<GridProps> = () => {
  const { isLoading, isNextAvailable, images, next } = useLoadImages(24);
  const [active, setActive] = React.useState(false);
  const [sourceImageUrl, setSourceImageUrl] = React.useState('');

  function showImageFullscreen(active: boolean, sourceImageUrl?: string) {
    setActive(active);
    if (sourceImageUrl) {
      setSourceImageUrl(sourceImageUrl);
    }
  }

  return (
    <div className={styles.Container}>
      <MasonryInfiniteGrid
        threshold={300}
        onRequestAppend={(event: any) => {
          if (isNextAvailable) {
            event.wait();

            next().then(() => {
              event.ready();
            });
          }
        }}
      >
        {images.map((image) => {
          return (
            <Tile showImageFullscreen={showImageFullscreen} className={styles.Tile} key={image.id} image={image} />
          );
        })}
      </MasonryInfiniteGrid>

      {isNextAvailable && !isLoading && <Spinner />}

      <Modal
        isOpen={active}
        shouldCloseOnOverlayClick={true}
        preventScroll={true}
        onRequestClose={() => showImageFullscreen(false)}
      >
        <div className="ReactModal__Close" onClick={() => showImageFullscreen(false)}>✕</div>
        <img src={sourceImageUrl} onClick={() => showImageFullscreen(false)} alt="" />
      </Modal>
    </div>
  );
};
