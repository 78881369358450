import React from 'react';
import cn from 'classnames';
import styles from './Tile.module.scss';
import { TelegramImage } from '../../interfaces/TelegramImage';

export interface TileProps {
  className?: string;
  image: Partial<TelegramImage>;
  showImageFullscreen: (active: boolean, sourceImageUrl?: string) => void;
}

export const Tile: React.FC<TileProps> = ({ className, image, showImageFullscreen }) => {
  return (
    <div className={cn(styles.Tile, className)}>
      <div className={styles.Wrapper} style={{ aspectRatio: `${image.width} / ${image.height}` }}>
        {image.url && <img alt=""
                           loading="lazy"
                           src={image.url}
                           className={styles.Image}
                           onClick={() => showImageFullscreen(true, image.url)} />}
      </div>
    </div>
  );
};
