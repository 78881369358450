import React from 'react';
import styles from './MainPage.module.scss';
import { Grid } from '../../components/Grid/Grid';

export interface MainPageProps {}

export const MainPage: React.FC<MainPageProps> = () => {
  return (
    <div className={styles.MainPage}>
      <Grid />
    </div>
  );
};
