import React from 'react';
import { HashRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Pages } from './constants/pages';
import { MainLayout } from './layouts/MainLayout/MainLayout';
import { MainPage } from './pages/MainPage/MainPage';

export default function () {
  return (
    <HashRouter>
      <Routes>
        <Route
          path={Pages.MAIN}
          element={
            <MainLayout>
              <MainPage />
            </MainLayout>
          }
        />
        <Route path="*" element={<Navigate replace to={Pages.MAIN} />} />
      </Routes>
    </HashRouter>
  );
}
