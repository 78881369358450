import React from 'react';
import config from '../config';
import { TelegramImage } from '../interfaces/TelegramImage';

const skeleton = [
  {
    "id": "1",
    "width": 1080,
    "height": 1082
  },
  {
    "id": "2",
    "width": 959,
    "height": 959
  },
  {
    "id": "3",
    "width": 853,
    "height": 1280
  },
  {
    "id": "4",
    "width": 876,
    "height": 1223
  },
  {
    "id": "5",
    "width": 1024,
    "height": 1280
  },
  {
    "id": "6",
    "width": 1024,
    "height": 1280
  },
  {
    "id": "7",
    "width": 1280,
    "height": 720
  },
  {
    "id": "8",
    "width": 853,
    "height": 1280
  },
  {
    "id": "9",
    "width": 853,
    "height": 1280
  },
  {
    "id": "10",
    "width": 853,
    "height": 1280
  },
  {
    "id": "11",
    "width": 853,
    "height": 1280
  },
  {
    "id": "12",
    "width": 853,
    "height": 1280
  },
  {
    "id": "13",
    "width": 720,
    "height": 1280
  },
  {
    "id": "14",
    "width": 1280,
    "height": 1280
  },
  {
    "id": "15",
    "width": 787,
    "height": 1280
  },
  {
    "id": "16",
    "width": 915,
    "height": 1280
  },
  {
    "id": "17",
    "width": 923,
    "height": 1280
  },
  {
    "id": "18",
    "width": 1280,
    "height": 1187
  },
  {
    "id": "19",
    "width": 865,
    "height": 1153
  },
  {
    "id": "20",
    "width": 960,
    "height": 1280
  },
  {
    "id": "21",
    "width": 743,
    "height": 800
  },
  {
    "id": "22",
    "width": 1075,
    "height": 1280
  },
  {
    "id": "23",
    "width": 1080,
    "height": 1080
  },
  {
    "id": "24",
    "width": 1280,
    "height": 960
  }
];

export const useLoadImages = (limit: number) => {
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [isNextAvailable, setNextAvailable] = React.useState<boolean>(true);
  const [page, setPage] = React.useState<number>(1);
  const [images, setImages] = React.useState<Partial<TelegramImage>[]>(skeleton);

  const next = async () => {
    setLoading(true);

    fetch(`${config.BACKEND_URL}/posts?limit=${limit}&page=${page}`)
      .then((response) => {
        return response.json();
      })
      .then(({ result }) => {
        if (result.length === 0) {
          return setNextAvailable(false);
        }

        if (page === 1) {
          setImages(result);
        } else {
          setImages([...images, ...result]);
        }

        setPage(page + 1);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    next();
  }, []);

  return {
    isLoading,
    isNextAvailable,
    images,
    next,
  };
};
